import React from 'react';
import { useEffect } from 'react'

const useGapi = () => {
  const [gapiAccessToken, setGapiAccessToken] = React.useState<string>(null);
  useEffect(() => {
    if (gapiAccessToken) {
      const handleClientLoad = () => window.gapi.load('client', initClient)
  
      const initClient = () => {
        window.gapi.client.setToken({access_token: gapiAccessToken})
      }
  
      const script = document.createElement('script')
  
      script.src = 'https://apis.google.com/js/api.js'
      script.async = true
      script.defer = true
      script.onload = handleClientLoad
  
      document.body.appendChild(script)
  
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [gapiAccessToken])

  return {
    setGapiAccessToken
  }
}

export default useGapi
