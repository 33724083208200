export const HAIFUBUTSU_TOPIC_NAME = "資料"
export const SHUKUDAI_TOPIC_NAME = "課題"
export const HENKYAKU_TOPIC_NAME = "返却"
export const QUESTIONNAIRE_TOPIC_NAME = "アンケート"
export const GROUP_EVENT_TYPE_HAIFUBUTSU = "HAIFUBUTSU"
export const GROUP_EVENT_TYPE_SHUKUDAI = "SHUKUDAI"
export const GROUP_EVENT_TYPE_QUESTIONNAIRE = "QUESTIONNAIRE"

export enum PrinthubMobiscrollViewMode {
  calendar = "calendar",
  timeline = "timeline",
}

export enum PrinthubInitializeFailedReason {
  firestorePermissionDenied = "firestorePermissionDenied",
  firestoreDomainExistsAndIamNotAdmin = "firestoreDomainExistsAndIamNotAdmin",
}

export const GOOGLE_OAUTH_SCOPES = [
  "classroom.rosters.readonly", // 一覧表示時名前取得
  "classroom.topics", // アプリ用topics作成
  "classroom.announcements", // 再提出時に個別生徒向けアナウンス作成
  "drive.file", // pickerでpick後のfileをcourseWorkなどにひもづける
  "classroom.courses.readonly", // カレンダー表示時コース一覧取得
  "classroom.coursework.students", // CRUD
  "classroom.courseworkmaterials", // CRUD
  "drive.readonly", // studentSubmission添付ファイルのmimeType取得と、PDFファイルのダウンロード
  "drive.appdata", // application specific data
]

export const GOOGLE_OAUTH_FULL_SCOPES = GOOGLE_OAUTH_SCOPES

export const KADAI_COLOR = "rgba(193, 82, 65, 0.3)"
export const SIRYOU_COLOR = "rgba(236, 210, 93, 0.3)"