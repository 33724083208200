import PropTypes from 'prop-types'
import { createContext, useEffect, useReducer, useState } from 'react'
import type { FC, ReactNode } from 'react'
import axios from 'axios';
import { useRouter } from 'next/router'
import useGapi from 'src/hooks/use-gapi';
import { signIn, signOut, useSession } from 'next-auth/react';
import { scopeDiff } from 'src/lib/scope-diff';
import { AuthorizationErrorModal } from 'src/components/authorization-error-modal';
import { GOOGLE_OAUTH_SCOPES } from 'src/constants';

export interface AuthContextValue {
  platform: 'GoogleClassroom'
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthContext = createContext<AuthContextValue>({
  platform: 'GoogleClassroom',
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const router = useRouter()
  const {setGapiAccessToken} = useGapi()
  const { data: session, status: sessionStatus } = useSession()
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false)

  const setupAxios = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const setupAccessToken = (accessToken: string) => {
    if (accessToken) {  
      setupAxios(accessToken)
      setGapiAccessToken(accessToken)
    }  
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const scopeStr:string = (session as any).scope
        const result = scopeDiff(GOOGLE_OAUTH_SCOPES, scopeStr)
        const accessToken = (session as any)?.accessToken
        if(result.length === 0){ // 全部ある場合
          setupAccessToken(accessToken)
        } else { // 必要最低限の権限が足りない場合
          setErrorModalOpen(true)
        }
      } catch (error) {
        // handleError(error)
      }
    }
    // 現状ログインなしでも見れる必要がある/docsもここに来るのでログイン画面にリダイレクトしてしまうため、排除する
    if(router.pathname.includes("/docs") || router.pathname.includes("/terms") || router.pathname.includes("/privacy")) {
    } else {
      if (sessionStatus !== "loading") {
        initialize()
      }
    }
  }, [sessionStatus])

  useEffect(() => {
    // access tokenローテーションが失敗した時
    // https://authjs.dev/guides/refresh-token-rotation#client-side
    if ((session as any)?.error === "RefreshAccessTokenError") {
      signIn(); // Force sign in to hopefully resolve error
    }
  }, [session]);

  return (
    <>
      <AuthContext.Provider
        value={{
          platform: 'GoogleClassroom',
        }}
      >
        {children}
      </AuthContext.Provider>
      <AuthorizationErrorModal 
        open={errorModalOpen}
        setOpen={setErrorModalOpen}
      />
    </>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const AuthConsumer = AuthContext.Consumer