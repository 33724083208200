import type { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import type { FC } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { RTL } from '../components/rtl'
import { SplashScreen } from '../components/splash-screen'
import { gtmConfig } from '../config'
import { AuthConsumer, AuthProvider } from '../contexts/google-classroom-context'
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context'
import { gtm } from '../lib/gtm'
import { createTheme } from '../theme'
import { createEmotionCache } from '../utils/create-emotion-cache'
import '../i18n'
import 'src/styles/fullcalendar-override.css'
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { PrinthubTourProvider } from 'src/components/onboarding/printhub-tour-provider';
import { Favicon } from 'src/components/mata-switcher/favicon';
import { getTenantDocsAppName } from 'src/lib/tenant';
import AppleTouchStartupImage from 'src/components/mata-switcher/apple-touch-startup-image';
import { Twitter } from 'src/components/mata-switcher/twitter';
import { OG } from 'src/components/mata-switcher/og';
import dayjs, { Dayjs } from 'dayjs';
import ja from 'dayjs/locale/ja';
import { SessionProvider, signOut } from "next-auth/react"
import { IconButton, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// mswをnextjs用にセットアップ
// https://github.com/vercel/next.js/tree/canary/examples/with-msw
if (process.env.NODE_ENV === 'development') {
  require('../mocks')
}

LicenseInfo.setLicenseKey(
  process.env.NEXT_PUBLIC_MUI_LICENSE_KEY,
)

type EnhancedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps } } = props

  const getLayout = Component.getLayout ?? ((page) => page)
  const toastComponent = <Stack direction="row" spacing={1}><p>tokenの期限が切れました。リロードしてください。</p><IconButton color="secondary" aria-label="add an alarm">
    <RefreshIcon />
  </IconButton></Stack>
  
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // https://tanstack.com/query/v4/docs/react/guides/important-defaults
        // 別windowにfocusして戻ってきてもrefetchしない
        refetchOnWindowFocus: false, // default: true
        // PrintCalendarRenderEventContentなどで、useQuery呼び出し元componentがunmountされてもキャッシュ効くようにする設定
        refetchOnMount: false,
      },
    },
    queryCache: new QueryCache({
      // react-queryのglobal error callbackの書き方
      // https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
      onError: (error) => {
        if ((error as any).response?.status === 401) {
          window.location.reload() // toast出さずに自動でリロードする
          // console.log("error code is 401")
          // toast(toastComponent, {
          //   duration: 10000000,
          //   id: "error-code-is-401"
          // })
        }
      }
    }),
  })

  useEffect(() => {
    gtm.initialize(gtmConfig)
    dayjs.locale(ja);
  }, [])

  const appName = getTenantDocsAppName()

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{appName}</title>
        {/* noindexを解除 */}
        {/* https://developers.google.com/search/docs/crawling-indexing/block-indexing?hl=ja */}
        {/* <meta name="robots" content="noindex" /> */}
        <meta name="viewport" content="initial-scale=1, width=device-width" />

        <meta name="application-name" content={appName} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={appName} />
        <meta name="description" content={appName} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />

        <Favicon />
        <link rel="manifest" href="/manifest.json" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />
        <Twitter />
        <OG />        
        <AppleTouchStartupImage />
      </Head>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SessionProvider session={session}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {({ settings }) => (
                    <ThemeProvider
                      theme={createTheme({
                        direction: settings.direction,
                        responsiveFontSizes: settings.responsiveFontSizes,
                        mode: "light",
                      })}
                    >
                      <PrinthubTourProvider>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          <Toaster position="top-center" />
                          <AuthConsumer>
                            {(auth) => getLayout(<Component {...pageProps} />)}
                          </AuthConsumer>
                        </RTL>
                      </PrinthubTourProvider>
                    </ThemeProvider>
                  )}
                </SettingsConsumer>
              </SettingsProvider>
            </AuthProvider>
          </QueryClientProvider>
        </SessionProvider>
      </LocalizationProvider>
    </CacheProvider>
  )
}

export default App
